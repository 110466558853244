import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import RelatedMedia from "../components/related-media"
import SEO from "../components/seo"
import VideoHistoryBlock from "../components/VideoHistoryBlock"
import Breadcrumbs from "../components/breadcrumb"

const PageTemplate = ({ data, pageContext }) => {
  const title = pageContext.node.frontmatter.title
  const html = pageContext.node.html
  const relatedMedia = data.markdownRemark.frontmatter.related_media

  return (
    <Layout>
      <SEO title={title} />
      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
      <Breadcrumbs />
      <Row id="content">
        <Col s="12" md="8" id="main-content">
          <Row>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Row>
        </Col>
        <Col s="12" md="4">
          <aside id="sidebar-second" className="sidebar">
            <RelatedMedia relatedMedia={relatedMedia} />
            <VideoHistoryBlock />
          </aside>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        related_media {
          frontmatter {
            mid
            title
            name
            type
            path
            ext
          }
        }
      }
      html
    }
  }
`
export default PageTemplate
